 const appPropertyRouter = [
    {
        path: '/wygl/wyzc',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/appProperty/register/registerList')
    }  , {
         path: '/appProperty/register/insertRegister',
         component: () => import('@/views/appProperty/register/insertRegister')
     },
     {
         path: '/wygl/wyrz',
         meta: {
             keepAlive: true
         },
         component: () => import('@/views/appProperty/join/joinList.vue')
     } , {
         path: '/appProperty/join/insertJoin',
         component: () => import('@/views/appProperty/join/insertJoin')
     }, {
        path: '/wygl/yggl',
        component: () => import('@/views/appProperty/people/list')
    },
    {
        path: '/appProperty/people/add',
        component: () => import('@/views/appProperty/people/add')
    },
    {
        path: '/lgpz',
        component: () => import('@/views/appProperty/people/lgpz')
    },
    // {
    //     path: '/lgpzAdd',
    //     component: () => import('@/views/appProperty/people/lgpzAdd')
    // },
    {
        path: '/ywhjdpz',
        component: () => import('@/views/appProperty/people/ywhjdpz')
    },
    {
        path: '/appProperty/people/ywhjpz',
        component: () => import('@/views/appProperty/people/ywhjpz')
    },
    {
        path: '/wygdlb',
        component: () => import('@/views/appProperty/register/wygdlbList')
    },
    {
        path: '/gdgl',
        component: () => import('@/views/appProperty/register/gdgl')
    },
    {
        path: '/appProperty/register/typeCopy',
        component: () => import('@/views/appProperty/register/typeCopy')
    },
    {
        path: '/wyjcpz',
        component: () => import('@/views/appProperty/register/wyjcpz')
    },
    {
        path: '/ldfhgl',
        component: () => import('@/views/appProperty/roomNum/ldfhgl')
    },
    {
        path: '/jtfh',
        component: () => import('@/views/appProperty/roomNum/jtfh')
    },
    {
        path: '/appProperty/roomNum/jtfhAdd',
        component: () => import('@/views/appProperty/roomNum/jtfhAdd')
    },
]

 export default appPropertyRouter