import { instance } from './ajax.js'

/******  物业注册  ******/

export const AppPropertyRegisterApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/appPropertyRegister/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/govern/v1/appPropertyRegister', params)
    },
    update(params) {
        return instance.put('/api/govern/v1/appPropertyRegister', params)
    },
    detail(params) {
        return instance.get(`/api/govern/v1/appPropertyRegister/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/appPropertyRegister?id=${params.id}`)
    },
    selectProperty(params) {
        return instance.get(`/api/govern/v1/appPropertyRegister/select?appDefineId=${params.appDefineId}`)
    },
    appPropertyTypelist(obj) {
        return instance.get(`/api/govern/v1/appPropertyType/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    appPropertyTypeAdd(params) {
        return instance.post('/api/govern/v1/appPropertyType', params)
    },
    appPropertyTypeUpdate(params) {
        return instance.put('/api/govern/v1/appPropertyType', params)
    },
    appPropertyTypeDelete(params) {
        return instance.delete(`/api/govern/v1/appPropertyType?id=${params.id}`)
    },
    appPropertyTypeDetail(params) {
        return instance.get(`/api/govern/v1/appPropertyType/detail/${params.id}`)
    },
    copyType(params) {
        return instance.put('/api/govern/v1/appPropertyType/copyType', params)
    },
    managerlist(obj) {
        return instance.get(`/api/govern/v1/appPropertyContent/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    managerdetail(params) {
        return instance.get(`/api/govern/v1/appPropertyContent/detail/${params.id}`)
    },
}


export const AppPropertyJoinApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/appPropertyJoin/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/govern/v1/appPropertyJoin', params)
    },
    update(params) {
        return instance.put('/api/govern/v1/appPropertyJoin', params)
    },
    detail(params) {
        return instance.get(`/api/govern/v1/appPropertyJoin/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/appPropertyJoin?id=${params.id}`)
    },
    propertyRegion(params) {
        return instance.get('/api/auth/v1/basicRegion/propertyRegion', {params})
    },
}

export const AppPropertyStaffApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/appPropertyStaff/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/govern/v1/appPropertyStaff', params)
    },
    update(params) {
        return instance.put('/api/govern/v1/appPropertyStaff', params)
    },
    detail(params) {
        return instance.get(`/api/govern/v1/appPropertyStaff/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/appPropertyStaff?id=${params.id}`)
    }
}

export const appPropertyBuildingManagerApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/govern/v1/appPropertyBuildingManager', params)
    },
    update(params) {
        return instance.put('/api/govern/v1/appPropertyBuildingManager', params)
    },
    detail(params) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/appPropertyBuildingManager?id=${params.id}`)
    },
    getRegionWhenAdd(params) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/getRegionWhenAdd/${params.villageId}`)
    },
    staffBuildingAddList(obj) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/staffBuildingAddList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    getRegisterByVillage(params) {
        return instance.get(`/api/govern/v1/appPropertyRegister/getRegisterByVillage/${params.villageId}`)
    },
    buildingManagerSuperviselist(obj) {
        return instance.get(`/api/govern/v1/buildingManagerSupervise/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    managerSupervisePeopleList(obj) {
        return instance.get(`/api/govern/v1/buildingManagerSupervise/managerSupervisePeopleList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    supervise(params) {
        return instance.post('/api/govern/v1/buildingManagerSupervise/supervise', params)
    },
    roomList(obj) {
        return instance.get(`/api/resident/v1/familyRoom/roomList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    changeManager(params) {
        return instance.post('/api/govern/v1/appPropertyBuildingManager/change', params)
    },
    changeUpdate(obj) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/getRegionWhenUpdate/${obj.villageId}/${obj.id}`)
    },
    staffBuildingChangeList(obj) {
        return instance.get(`/api/govern/v1/appPropertyBuildingManager/staffBuildingChangeList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    viewInterconnectionCode(obj) {
        return instance.get('/api/auth/v2/basicRegion/viewInterconnectionCode', { params: obj })
    },
    // downloadAllQr(obj) {
    //     return instance.get('/api/auth/v2/basicRegion/downloadAllQr', { params: obj , responseType: 'blob'})
    // },
    downloadAllQr(params) {
        return instance.get(`/api/auth/v2/basicRegion/downloadAllQr`, { params: params, responseType: 'blob'  })
    },
}

export const AppPropertyConfigApi = {
    list(obj) {
        return instance.get(`/api/govern/v1/appPropertyConfig/list/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    add(params) {
        return instance.post('/api/govern/v1/appPropertyConfig', params)
    },
    update(params) {
        return instance.put('/api/govern/v1/appPropertyConfig', params)
    },
    detail(params) {
        return instance.get(`/api/govern/v1/appPropertyConfig/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/govern/v1/appPropertyConfig?id=${params.id}`)
    }
}

export const basicRegionRoomApi = {
    list(params) {
        return instance.get(`/api/auth/v1/basicRegionRoom/list/${params.id}`)
    },
    add(params) {
        return instance.post('/api/auth/v1/basicRegionRoom', params)
    },
    update(params) {
        return instance.put('/api/auth/v1/basicRegionRoom', params)
    },
    detail(params) {
        return instance.get(`/api/auth/v1/basicRegionRoom/detail/${params.id}`)
    },
    delete(params) {
        return instance.delete(`/api/auth/v1/basicRegionRoom?id=${params.id}`)
    },
    batchImport(params) {
        return instance.post('/api/auth/v1/basicRegionRoom/batchImport', params)
    },
    roomList(obj) {
        return instance.get(`/api/resident/v1/familyRoom/roomList/${obj.page.currentPage}/${obj.page.pageSize}`, { params: obj.params })
    },
    roomAdd(params) {
        return instance.post('/api/resident/v1/familyRoom', params)
    },
    roomDelete(params) {
        return instance.delete(`/api/resident/v1/familyRoom/${params.familyId}`)
    },
    
    excelupdate(params, fileData) {
        let str = '?'
        for (let key in params) {
            str = str + key + '=' + params[key] +'?'
        }
        return instance.put('/api/resident/v1/familyRoom/excel/read?communityId='+params.communityId+'&villageId='+params.villageId, fileData)
    },
}