<template>
    <el-dialog
        title="选择小区"
        :visible.sync="treeVisible"
        append-to-body
        width="600px">
        <div class="top-search-cons">
            <div class="prepend-form-item">
                <div class="prepend-item-label">小区名称</div>
                <el-input v-model="filterText" class="prepend-item-input" placeholder="请输入"></el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="searchFilterText">查询</el-button>
        </div>
        <div class="tree-modal-cons">
            <el-tree
                ref="menuTree"
                :data="menuTreeData"
                :props="{
                    children: 'childBasicRegionDtoList',
                    label: 'regionName',
                    disabled: disabledFn
                }"
                :highlight-current="false"
                :show-checkbox="true"
                node-key="id"
                :default-expand-all="defaultExpandAll"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :check-strictly="true"
                :filter-node-method="filterNode"
                @check="checkNode"
            ></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="treeVisible = false">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="selectTreeNode">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { AppPropertyJoinApi } from "@/apis/appProperty";
import { mapState } from 'vuex'

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        showCheckbox: {
            type: Boolean,
            default: true
        },
        defaultExpandAll: {
            type: Boolean,
            default: true
        },
        level: {
            type: [String, Number],
            default: 4
        },
        isCanUpdate: {
            type: Boolean,
            default: true
        },
        rootDisable: {
            type: Boolean,
            default: true
        },
        isClickClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            menuTreeData: [],
            treeVisible: false,
            filterText: '',
            isActivated: false,
            parentId: ''
        }
    },
    computed: {
      ...mapState({
          userRegionId: 'userRegionId'
      })
    },
    mounted() {
        // this.getMenuTree()
    },
    activated() {
        if (this.isActivated) {
            this.getMenuTree()
        }
    },
    deactivated() {
        this.isActivated = true
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.regionName.indexOf(value) !== -1;
        },
        searchFilterText() {
            this.$refs.menuTree.filter(this.filterText);
        },
        getMenuTree() {
            let params = {
                communityId: this.parentId
            }
            AppPropertyJoinApi.propertyRegion(params).then((res) => {
                if (res.code === 0) {
                    let listTree = res.data || []
                    listTree.forEach((ev) => {
                        ev.disabled = true
                    })
                    this.menuTreeData = listTree
                }
            })
        },
        disabledFn(data) {
            if (data.level < this.level) {
                return true
            }
        },
        selectTreeNode() {
            if (this.showCheckbox) {
                this.$emit('selectTree', this.$refs.menuTree.getCheckedKeys(), this.$refs.menuTree.getCheckedNodes())
                if (this.isClickClose) {
                    this.treeVisible = false
                }
            } else {
                let nodeKeys = this.$refs.menuTree.getCheckedKeys()
                let nodeItem = this.$refs.menuTree.getCheckedNodes()
                this.$emit('selectTree', nodeKeys[0] || '', nodeItem[0] || {})
                if (this.isClickClose) {
                    this.treeVisible = false
                }
            }

        },
        setCheckedKeys(v) {
            if (this.$refs.menuTree) {
                if (this.showCheckbox) {
                    this.$refs.menuTree.setCheckedKeys(v)
                } else {
                    this.$refs.menuTree.setCheckedKeys([v])
                }
            } else {
                this.$nextTick().then(() => {
                    if (this.showCheckbox) {
                        this.$refs.menuTree.setCheckedKeys(v)
                    } else {
                        this.$refs.menuTree.setCheckedKeys([v])
                    }
                })
            }
        },
        checkNode(v) {
            if (this.showCheckbox) {

            } else {
                this.$refs.menuTree.setCheckedKeys([v.id])
            }
        },
        openModal(e) {
            this.parentId = e
            console.log(e,'qwq')
            this.getMenuTree()
            this.treeVisible = true
        },
        closeModal() {
            this.treeVisible = false
        }
    }
}
</script>
<style scoped lang="less">
.tree-modal-cons {
    height: 400px;
    overflow: auto;
    margin-top: 20px;
}
.top-search-cons {
    display: flex;
    justify-content: space-between;
}
.prepend-form-item {
    display: flex;
    flex: 1;
    margin-right: 20px;
    .prepend-item-label {
        width: 100px;
        border: 1px solid #e6e6e6;
        background-color: #F3F4F6;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0 0 8px;
    }
    .prepend-item-input {
        flex: 1;
        overflow: hidden;
        ::v-deep {
            .el-input__inner {
                border-radius: 0 8px 8px 0;
            }
        }
    }
}
</style>