const statisticRouter = [
    {
        path: '/statistic/onLineDependcyData',
        component: () => import('@/views/statistic/onLineDependcyData')
    },
    {
        path: '/statistic/gridstatistics',
        component: () => import('@/views/statistic/gridstatistics')
    },
    {
        path: '/statistic/groupStatistics',
        component: () => import('@/views/statistic/groupStatistics')
    },
    {
        path: '/statistic/orgStatistics',
        component: () => import('@/views/statistic/orgStatistics')
    },
    {
        path: '/statistic/dataSummary',
        component: () => import('@/views/statistic/dataSummary')
    },
    {
        path: '/statistic/neighborStatistics',
        component: () => import('@/views/statistic/neighborStatistics')
    },
    {
        path: '/statistic/photographStatistics',
        component: () => import('@/views/statistic/photographStatistics')
    },
    {
        path: '/statistic/sqmygraphStatistics',
        component: () => import('@/views/statistic/sqmygraphStatistics')
    },
    {
        path: '/statistic/studyStatistics',
        component: () => import('@/views/statistic/studyStatistics')
    },
    {
        path: '/statistic/attestationStatistics',
        component: () => import('@/views/statistic/attestationStatistics')
    },
    {
        path: '/statistic/realTimeAuthenticationStatistics',
        component: () => import('@/views/statistic/realTimeAuthenticationStatistics')
    },
    {
        path: '/statistic/residentStatisticsByVillage',
        component: () => import('@/views/statistic/residentStatisticsByVillage')
    },
    {
        path: '/statistic/packageGroupStatistics',
        component: () => import('@/views/statistic/packageGroupStatistics')
    },
    {
        path: '/statistic/unJoinFamily',
        component: () => import('@/views/statistic/unJoinFamily')
    },
    {
        path: '/statistic/noAttestationFamily',
        component: () => import('@/views/statistic/noAttestationFamily')
    },
    {
        path: '/statistic/integralsummarystatistics',
        component: () => import('@/views/statistic/integralsummarystatistics')
    },
    {
        path: '/statistic/applyrulestatistics',
        component: () => import('@/views/statistic/applyrulestatistics')
    },
    {
        path: '/statistic/applyclassificationstatistics',
        component: () => import('@/views/statistic/applyclassificationstatistics')
    },
    {
        path: '/statistic/familyapplyranking',
        component: () => import('@/views/statistic/familyapplyranking')
    },
    {
        path: '/statistic/familyApplyList',
        component: () => import('@/views/statistic/familyApplyList')
    },
    {
        path: '/statistic/scoreApplyUnaudited',
        component: () => import('@/views/statistic/scoreApplyUnaudited')
    },
    {
        path: '/statistic/ruleClassificationCommunityRank',
        component: () => import('@/views/statistic/ruleClassificationCommunityRank')
    },
    {
        path: '/resident/residentMgr/peopleInfo/declarationSummary',
        component: () => import('@/views/statistic/declarationSummary')
    },
    {
        path: '/statistic/onlineapplystatistics',
        component: () => import('@/views/statistic/onlineapplystatistics')
    },
    {
        path: '/sysStatistics/exchangeSummaryStatistics',
        component: () => import('@/views/statistic/exchangeSummaryStatistics')
    },
    {
        path: '/sysStatistics/exchangePersonTimeStatistics',
        component: () => import('@/views/statistic/exchangePersonTimeStatistics')
    },
    {
        path: '/sysStatistics/companyExchangeRecord',
        component: () => import('@/views/statistic/companyExchangeRecord')
    },
    {
        path: '/statistic/scoreRuleStatistics',
        component: () => import('@/views/statistic/scoreRuleStatistics')
    },
    {
        path: '/statistic/giveScoreStatistics',
        component: () => import('@/views/statistic/giveScoreStatistics')
    },
    {
        path: '/statistic/distributionClassificationSummary',
        component: () => import('@/views/statistic/distributionClassificationSummary')
    },
    {
        path: '/statistic/reviewDurationStatistics',
        component: () => import('@/views/statistic/reviewDurationStatistics')
    },
    {
        path: '/statistic/familyGiveScoreStatistics',
        component: () => import('@/views/statistic/familyGiveScoreStatistics')
    },
    {
        path: '/statistic/communityFamilyScoreRank',
        component: () => import('@/views/statistic/communityFamilyScoreRank')
    },
    {
        path: '/statistic/applyStatisticsByAge',
        component: () => import('@/views/statistic/applyStatisticsByAge')
    },
    {
        path: '/statistic/applySummaryStatisticsByGov',
        component: () => import('@/views/statistic/applySummaryStatisticsByGov')
    },
    {
        path: '/statistic/onlineApplyStatisticsByGov',
        component: () => import('@/views/statistic/onlineApplyStatisticsByGov')
    },
    {
        path: '/statistic/familyApplyRateTrend',
        component: () => import('@/views/statistic/familyApplyRateTrend')
    },
    {
        path: '/statistic/scoreTrend',
        component: () => import('@/views/statistic/scoreTrend')
    },
    {
        path: '/statistic/applyTrend',
        component: () => import('@/views/statistic/applyTrend')
    },
    {
        path: '/statistic/giveScoreTrend',
        component: () => import('@/views/statistic/giveScoreTrend')
    },
    {
        path: '/statistic/exchangeTrend',
        component: () => import('@/views/statistic/exchangeTrend')
    },
    {
        path: '/statistic/applyRateTrend',
        component: () => import('@/views/statistic/applyRateTrend')
    },
    {
        path: '/statistic/authUnAuditStatistics',
        component: () => import('@/views/statistic/authUnAuditStatistics')
    },
    {
        path: '/statistic/warningFamilyApplyNum',
        component: () => import('@/views/statistic/warningFamilyApplyNum')
    },
    {
        path: '/statistic/warningFamilyApplyScore',
        component: () => import('@/views/statistic/warningFamilyApplyScore')
    },
    {
        path: '/statistic/warningReplaceApplyProportion',
        component: () => import('@/views/statistic/warningReplaceApplyProportion')
    },
    {
        path: '/statistic/warningPassiveGiveScoreStatistics',
        component: () => import('@/views/statistic/warningPassiveGiveScoreStatistics')
    },
    {
        path: '/statistic/warningGiveScoreStatistics',
        component: () => import('@/views/statistic/warningGiveScoreStatistics')
    },
    {
        path: '/statistic/warningAuditTimeout',
        component: () => import('@/views/statistic/warningAuditTimeout')
    },
    {
        path: '/sysStatistics/scoreRuleStatistics',
        component: () => import('@/views/statistic/scoreRuleStatistics')
    },
    {
        path: '/statistic/villageApplyRuleStatistics',
        component: () => import('@/views/statistic/ruleVillage')
    },
    {
        path: '/sysStatistics/ruleClassificationCommunityRank',
        component: () => import('@/views/statistic/ruleClassificationCommunityRank')
    },
    {
        path: '/sysStatistics/ruleInstanceParticipationAnalysis',
        component: () => import('@/views/statistic/ruleInstanceParticipationAnalysis')
    },
    {
        path: '/sysStatistics/ruleTypeParticipationAnalysis',
        component: () => import('@/views/statistic/ruleTypeParticipationAnalysis')
    },
    {
        path: '/sysStatistics/villageRuleInstanceParticipationAnalysis',
        component: () => import('@/views/statistic/villageRuleInstance')
    },
    {
        path: '/sysStatistics/villageRuleTypeParticipationAnalysis',
        component: () => import('@/views/statistic/villageRuleTypeParticipationAnalysis')
    },
    {
        path: '/zyfwtj/zytdbd',
        component: () => import('@/views/statistic/zytdbd')
    },
    {
        path: '/zyfwtj/zyzbd',
        component: () => import('@/views/statistic/zyzbd')
    },
    {
        path: '/zyfwtj/jcsj',
        component: () => import('@/views/statistic/jcsj')
    },
    {
        path: '/zfwwtj/zfpjfx',
        component: () => import('@/views/statistic/zfpjfx')
    },
    {
        path: '/zfwwtj/zfjhfx',
        component: () => import('@/views/statistic/zfjhfx')
    },

    {
        path: '/zfwwtj/zfcybd',
        component: () => import('@/views/statistic/zfcybd')
    },
    {
        path: '/zfwwtj/zfsbbd',
        component: () => import('@/views/statistic/zfsbbd')
    },
]

export default statisticRouter